<template>
  <l-default>
    <em style="margin-bottom: 10px">We are suspending our texting service starting the 5th of July.</em>
    <CShareThisInline/>
    <CStickyBtnGroup position="top">
      <CBackButton :to="{ name: target ? 'messages' : 'conversations', params: { target } }"></CBackButton>
    </CStickyBtnGroup>
    <CFormNewMessage @success="onSuccess" :target="target"></CFormNewMessage>
    <CAdSecondNumber/>
  </l-default>
</template>

<script>
import LDefault from '../../layouts/LDefault'
import CBackButton from '../../components/CBackButton'
import CFormNewMessage from '../../forms/CFormNewMessage'
import CStickyBtnGroup from '../../components/CStickyBtnGroup'
import CShareThisInline from '../../components/CShareThisInline'
import CAdSecondNumber from '../../components/CAdSecondNumber'

export default {
  name: 'PageNewConversation',
  components: { CAdSecondNumber, CStickyBtnGroup, CBackButton, LDefault, CFormNewMessage, CShareThisInline },
  props: {
    target: String
  },
  methods: {
    onSuccess ({ success, data: message }) {
      if (success) {
        this.$router.push({ name: 'messages', params: { target: message.target } })
      }
    }
  }
}
</script>

<style scoped>

</style>
