<template>
  <div class="o-uncontainer"><slot></slot></div>
</template>

<script>
export default {
  name: 'OCUncontainer'
}
</script>

<style lang="scss">
.o-uncontainer {
  margin: 0 (- vr(1.25));

  @include two-column() {
    margin: 0 (- vr($container-desktop-right)) 0 (- vr($container-desktop-left));
  }
}
</style>
