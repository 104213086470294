<template>
  <OButton
    :to="to"
    theme="primary"
    type="button"
  >
    Back
  </OButton>
</template>

<script>
import OButton from '@/objects/OButton'

export default {
  name: 'CBackButton',
  components: {
    OButton
  },
  props: {
    to: {
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
