<template>
  <OForm @submit="submit" :class="{submitting}">
    <!--

       IMPORTANT: field names MUST contains "search" keyword to disable safari autocomplete,
       which populates all fields with the same value

    -->
    <PhoneNumberInput
      id="to-number"
      v-model="form.to"
      :disabled="submitting || this.target"
      name="target-search"
      placeholder="10 digit number"
      required
      autocomplete="section-target tel"
      @invalid="toValid=false"
      @valid="toValid=true"
    >
      Number to message
    </PhoneNumberInput>
    <div class="input-group">
      <OFormInput
        :value="lastNumber"
        disabled
        name="own-search"
        placeholder="Random number will be assigned"
        autocomplete="section-spoof tel"
      >
        Sender number
      </OFormInput>
      <CCreditButton
        theme="primary"
        type="button"
        :disabled="submitting"
        v-if="lastNumber"
        :value="150"
        @click="changeNumber"
      >
        Change
      </CCreditButton>
    </div>

    <OFormSingleChoice
      style="margin-bottom: 2rem"
      v-model="type"
      type="buttons"
      :options="['text', 'image']"
    >
      Message type
    </OFormSingleChoice>

    <OFormInput
      ref="textInput"
      v-if="type === 'text'"
      v-model="form.message"
      :disabled="submitting"
      name="message-search"
      type="textarea"
      maxlength="320"
      placeholder="Message to send"
      required
      autocomplete="section-message off"
      @invalid="realValid=false"
      @valid="realValid=true"
    >
      Message
    </OFormInput>

    <OFormFileInput
      ref="imageInput"
      v-if="type === 'image'"
      v-model="form.fileToUpload"
      accept="image/png,image/jpeg,image/gif"
    >
      <template #placeholder>
        <div class="image-empty">
          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 0h24v24H0z" fill="none"/><path d="M21 6h-3.17L16 4h-6v2h5.12l1.83 2H21v12H5v-9H3v9c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zM8 14c0 2.76 2.24 5 5 5s5-2.24 5-5-2.24-5-5-5-5 2.24-5 5zm5-3c1.65 0 3 1.35 3 3s-1.35 3-3 3-3-1.35-3-3 1.35-3 3-3zM5 6h3V4H5V1H3v3H0v2h3v3h2z"/></svg>
          <span>Add an image</span>
        </div>
      </template>
      <template #default="{ value }">
        <div class="image">
          <img :src="createObjectURL(value)">
          <span>{{ value.name }}</span>
        </div>
      </template>
    </OFormFileInput>

    <FormGroup>
      <CCreditsInfoLine></CCreditsInfoLine>
    </FormGroup>

    <OFormError :message="error"></OFormError>

    <FormButtons>
      <CCreditButton
        type="submit"
        :disabled="submitting"
        style="width: 66%"
        :value="price"
        division="sms">
        Send
      </CCreditButton>
    </FormButtons>

    <OFormHelp>
      The cost of sending SMS is <em>10 credits</em> per message.<br/>
      Receiving SMS is <em>10 credits</em> per message.<br/>
      <em>
        Due to heavy regulations and filters, messages are censored in terms of profanity, spam and scam.
        Many of your messages will be filtered. Bad actors will be banned with no refunds.
      </em>
    </OFormHelp>

    <CPopup :show="showProfanity" variant="dialog">
      <OContainer>
        <h3>Inappropriate Language</h3>
        <p>
          Your message contains potentially bad language,
          particularly the word: {{ profanityWord }}
        </p>
        <p>
          Profanity is regulated, your message could be filtered and too much profanity could limit your account.
        </p>
        <OCUncontainer>
          <FormButtons variant="horizontal" class="mb-1">
            <OButton theme="none" @click="editMessage">Edit Message</OButton>
            <OButton theme="none" @click="sendProfanity">Send Anyway</OButton>
          </FormButtons>
        </OCUncontainer>
      </OContainer>
    </CPopup>
  </OForm>
</template>

<script>
import apiForm from '../mixins/apiForm'
import PhoneNumberInput from '../objects/forms/OFormPhoneNumberInput'
import OForm from '../objects/forms/OForm'
import FormButtons from '../objects/forms/FormButtons'
import OFormError from '../objects/forms/OFormError'
import OFormHelp from '../objects/forms/OFormHelp'
import OFormInput from '../objects/forms/OFormInput'
import FormGroup from '../objects/forms/FormGroup'
import CCreditsInfoLine from '../components/CCreditsInfoLine'
import { listMessages } from '../services/anonymousTexting'
import CCreditButton from '../components/CCreditButton'
import { mapActions } from 'vuex'
import OFormSingleChoice from '../objects/forms/OFormSingleChoice'
import OFormFileInput from '../objects/forms/OFormFileInput'
import Vue from 'vue'
import CPopup from '../components/CPopup'
import OButton from '../objects/OButton'
import OContainer from '../objects/containers/OContainer'
import OCUncontainer from '../objects/containers/OCUncontainer'

export default {
  name: 'CFormNewMessage',
  components: {
    OCUncontainer,
    OContainer,
    OButton,
    CPopup,
    OFormFileInput,
    OFormSingleChoice,
    CCreditButton,
    CCreditsInfoLine,
    FormGroup,
    OFormInput,
    OFormHelp,
    OFormError,
    FormButtons,
    OForm,
    PhoneNumberInput
  },
  mixins: [apiForm],
  props: {
    target: String
  },
  data: function () {
    return {
      lastNumber: null,
      type: 'text',
      showProfanity: false,
      profanityWord: null,
      form: {
        user: this.$store.getters.getEmail,
        pass: this.$store.getters.getPassword,
        to: this.target,
        number: null,
        message: null,
        profanity: 0
      }
    }
  },
  computed: {
    price () {
      // 10 credits per message
      return 10
    }
  },
  watch: {
    type (newValue) {
      // need to wait a tick for the new components to be shown
      Vue.nextTick(() => {
        let ctrl
        switch (newValue) {
          case 'text':
            ctrl = this.$refs.textInput
            break
          case 'image':
            ctrl = this.$refs.imageInput
            break
        }

        if (ctrl) {
          ctrl.$el.scrollIntoView({
            behavior: 'smooth',
            block: 'end'
          })
          // wait for scroll to finish and then focus the element
          let scrollTimeout
          const l = () => {
            clearTimeout(scrollTimeout)
            scrollTimeout = setTimeout(() => {
              document.removeEventListener('scroll', l)
              ctrl.focus({ preventScroll: false })
            }, 120)
          }
          document.addEventListener('scroll', l, { passive: true })
        }
      })
    }
  },
  methods: {
    ...mapActions(['fetchInfo']),
    editMessage () {
      this.showProfanity = false
      this.profanityWord = ''
      this.$refs.textInput.focus()
    },
    sendProfanity () {
      this.form.profanity = 1
      this.showProfanity = false
      this.profanityWord = ''
      this.submit()
    },
    async submit () {
      if (!this.form.to) {
        let valueNumber = document.getElementById('to-number').value
        this.form.to = '+1' + valueNumber.replace(/\D/g, '')
      }

      this.$callApi((api, atApi) => {
        switch (this.type) {
          case 'text':
            return atApi.sendMessage(this.form)
          case 'image':
            return atApi.sendMMS(this.form)
        }
      }, {
        onFail ({ error: { type, message: profanityWord } = {} }) {
          if (type === 'PROFANITY' && !this.form.profanity) {
            this.showProfanity = true
            this.profanityWord = profanityWord
            this.error = ''
            return false
          }
        }
      })
    },
    async changeNumber () {
      if (await this.$callApi(async (api, atApi) => {
        const response = await atApi.changeNumber({
          user: this.form.user,
          pass: this.form.pass,
          to: this.target
        })
        await this.fetchInfo()
        return response
      }, { force: true, emitOnSuccess: false })) {
        this.lastNumber = null
      }
    },
    createObjectURL (file) {
      return URL.createObjectURL(file)
    }
  },
  async beforeMount () {
    this.showLoader()
    try {
      await Promise.all([
        (async () => {
          if (this.target) {
            const { success, data: { logs: [message] = [] } = {} } = await listMessages({
              user: this.$store.getters.getEmail,
              pass: this.$store.getters.getPassword,
              target: this.target,
              id: 0,
              limit: 1,
              offset: 0,
              direction: 'after',
              sort: 'DESC'
            })
            if (success && message) {
              this.lastNumber = message.service
            }
          }
        })(),
        this.fetchInfo()
      ])
    } finally {
      this.hideLoader()
    }
  }
}
</script>

<style lang="scss" scoped>
.hint {
  color: #666666;
}

.error {
  color: #ff0000;
}

.hint, .error {
  font-size: 0.75em;
  line-height: normal;
  margin-bottom: 1em;
}

.bitcoin-icon {
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
}

.input-group {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;

  @include two-column() {
    flex-direction: row;
    align-items: flex-end;
  }

  .form-group {
    margin: 0;
    min-width: 50%;
    flex: 1 1 auto;
  }

  margin: 0 0 $vertical-margin;
}

.o-form__fileinput {
  .image-empty {
    place-self: center;
    margin: auto;
    padding: 1em;
    text-align: center;
  }

  .image {
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    span {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      background: transparentize(#000, 0.66);
      color: #fff;
      padding: 0.5em 1em;
    }
  }

  .image-empty {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    color: $primary;

    .icon {
      fill: $primary;
      width: 4em;
      height: auto;
      margin-bottom: $vertical-margin;
    }

    span {

    }
  }
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: $vertical-margin/2;
}
</style>
