<template>
  <transition name="popup">
    <div v-show="show" class="c-popup" :class="`c-popup--${variant}`" @click.prevent.stop="onClick">
      <div class="c-popup__wrapper">
        <div class="c-popup__container">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'CPopup',
  props: {
    show: Boolean,
    variant: String
  },
  watch: {
    show (v) {
      if (v) {
        document.body.classList.add('popup-shown')
      } else {
        document.body.classList.remove('popup-shown')
      }
    }
  },
  methods: {
    onClick () {
      this.$emit('close')
    }
  },
  mounted () {
    if (this.show) {
      document.body.classList.add('popup-shown')
    }
  },
  beforeDestroy () {
    document.body.classList.remove('popup-shown')
  }
}
</script>

<style lang="scss" scoped>
.c-popup {
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;

  &__wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  &__container {
    box-sizing: border-box;
    width: fit-content;
    height: fit-content;
    max-width: 90vw;
    max-height: 90vh;
    margin: 0 auto;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    overflow: hidden;
  }

  &--dialog {
    .c-popup__container {
      background: white;
    }
  }
}

.popup-enter {
  opacity: 0;
}

.popup-leave-active {
  opacity: 0;
}

.popup-enter .c-popup__container,
.popup-leave-active .c-popup__container {
  transform: scale(1.1);
}
</style>

<style>
body.popup-shown {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}
</style>
