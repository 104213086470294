<template>
  <div v-if="message || $slots.default" class="o-form__message" :class="`o-form__message--${type}`">
    <p class="o-form__message__text">
      <slot>{{message}}</slot>
    </p>
  </div>
</template>

<script>
export default {
  name: 'OFormMessage',
  props: {
    message: String,
    type: String
  }
}
</script>

<style lang="scss">
  .o-form__message {
    color: $primary;
    margin: 0 0 vr(1);
    overflow: visible;
    animation: mb-in 0.125s;
  }

  .o-form__message--error {
  }

  .o-form__message--success {
  }

  .o-form__message__text {
    margin: 0;
    display: inline-block;
    animation: pulse 0.25s 0.125s 6, scale-in 0.125s;
  }

  @keyframes mb-in {
    0% {
      margin-bottom: -1.5em;
    }
    100% {
      margin-bottom: vr(1);
    }
  }

  @keyframes scale-in {
    0% {
      transform: scale(0.1)
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
      transform-origin: 25% 50%;
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
