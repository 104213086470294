<template>
  <CStickyBar :position="position">
    <OCUncontainer>
      <OContainer class="sticky-button-container">
        <FormButtons variant="horizontal">
          <slot></slot>
        </FormButtons>
      </OContainer>
    </OCUncontainer>
  </CStickyBar>
</template>

<script>
import CStickyBar from './CStickyBar'
import OCUncontainer from '../objects/containers/OCUncontainer'
import OContainer from '../objects/containers/OContainer'
import FormButtons from '../objects/forms/FormButtons'

export default {
  name: 'CStickyBtnGroup',
  components: { FormButtons, OContainer, OCUncontainer, CStickyBar },
  props: {
    position: String
  }
}
</script>

<style lang="scss" scoped>
.sticky-button-container {
  transition: padding 0.25s;

  .btn-group {
    // remove top padding as it breaks the pinned state
    padding: 0;

    background: #f5f8ff;
  }

  .sticky-pinned & {
    padding: 0;
  }
}
</style>
