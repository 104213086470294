<template>
  <div class="sticky-container" :class="`sticky-${position} ${pinned ? 'sticky-pinned' : ''}`">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'CStickyBar',
  data: () => ({
    pinned: false
  }),
  props: {
    position: {
      type: String,
      required: true
    }
  },
  mounted () {
    this.observer = new IntersectionObserver(
      ([e]) => { this.pinned = e.intersectionRatio < 1 },
      { threshold: [1] }
    )
    this.observer.observe(this.$el)
  },
  beforeDestroy () {
    this.observer.disconnect()
  }
}
</script>

<style lang="scss" scoped>
.sticky {
  &-container {
    position: sticky;
    z-index: 100;
  }

  &-top {
    // need to be partially hidden for the detection to work
    top: -1px;
  }
}
</style>
