<template>
  <OFormMessage v-bind="$attrs" v-on="$listeners" type="error"></OFormMessage>
</template>

<script>
import OFormMessage from './OFormMessage'
export default {
  name: 'OFormError',
  components: { OFormMessage }
}
</script>
