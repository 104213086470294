<template>
  <FormGroup
    class="o-form__fileinput o-tooltip-container"
  >
    <OFormLabel
      class="o-form__fileinput__container"
      :class="{ dragging: dragging, dirty: !isEmpty }"
    >
      <input
        ref="input"
        type="file"
        :accept="accept"
        :capture="capture"
        :multiple="multiple"
        :required="required"
        @input="onInput"
      >
      <div class="o-form__fileinput__container__label">
        <slot v-if="isEmpty" name="placeholder">{{ placeholder }}</slot>
        <slot v-if="!isEmpty" :value="lazyValue">
          <ul>
            <li v-for="(file, index) in value" :key="index">{{ file.name }}</li>
          </ul>
        </slot>
      </div>
    </OFormLabel>
  </FormGroup>
</template>

<script>
import { deepEqual, wrapInArray } from '../../utils/primitives'
import FormGroup from './FormGroup'
import OFormLabel from './OFormLabel'

export default {
  name: 'OFormFileInput',
  components: { OFormLabel, FormGroup },
  model: {
    prop: 'value',
    event: 'change'
  },

  inheritAttrs: false,
  data: () => ({
    dragging: 0,
    lazyValue: null
  }),
  props: {
    accept: String,
    capture: Boolean,
    multiple: Boolean,
    placeholder: String,
    required: Boolean,
    value: {
      default: undefined,
      validator: val => {
        return wrapInArray(val).every(v => v != null && typeof v === 'object')
      }
    }
  },
  computed: {
    internalArrayValue () {
      return wrapInArray(this.internalValue)
    },
    internalValue: {
      get () {
        return this.lazyValue
      },
      set (val) {
        this.lazyValue = val
        this.$emit('change', this.lazyValue)
      }
    },
    isEmpty () {
      return this.internalArrayValue.length === 0
    }
  },
  watch: {
    value (v) {
      const value = this.multiple ? v : v ? [v] : []
      if (!deepEqual(value, this.$refs.input.files)) {
        // When the input value is changed programmatically, clear the
        // internal input's value so that the `onInput` handler
        // can be triggered again if the user re-selects the exact
        // same file(s). Ideally, `input.files` should be
        // manipulated directly but that property is readonly.
        this.$refs.input.value = ''
      }
    }
  },
  methods: {
    onInput ({ target: { files = [] } }) {
      this.internalValue = this.multiple ? files : files[0]
    },
    onDragEnter () {
      this.dragging++
    },
    onDragLeave () {
      this.dragging--
    },
    onDragOver (e) {
      e.preventDefault()
    },
    onDrop (e) {
      e.preventDefault()
      this.dragging = 0

      const files = e.dataTransfer.items
        ? Array.from(e.dataTransfer.items).filter(i => i.kind === 'file').map(i => i.getAsFile())
        : Array.from(e.dataTransfer.files)
      this.internalValue = this.multiple ? files : files[0]
    },
    focus (options) {
      if (this.$refs.input) {
        this.$refs.input.focus(options)
      }
    }
  },
  created () {
    this.onDragEnter = this.onDragEnter.bind(this)
    this.onDragLeave = this.onDragLeave.bind(this)
    this.onDragOver = this.onDragOver.bind(this)
    this.onDrop = this.onDrop.bind(this)
  },
  mounted () {
    this.dropTarget = document ? document.body : this.$el
    this.dropTarget.addEventListener('dragenter', this.onDragEnter, { passive: true })
    this.dropTarget.addEventListener('dragleave', this.onDragLeave, { passive: true })
    this.dropTarget.addEventListener('dragover', this.onDragOver)
    this.dropTarget.addEventListener('drop', this.onDrop)
  },
  beforeDestroy () {
    this.dropTarget.removeEventListener('dragenter', this.onDragEnter)
    this.dropTarget.removeEventListener('dragleave', this.onDragLeave)
    this.dropTarget.removeEventListener('dragover', this.onDragOver)
    this.dropTarget.removeEventListener('drop', this.onDrop)
  }
}
</script>

<style lang="scss" scoped>
.o-form__fileinput {
  position: relative;
  padding-top: 100%;

  @include two-column() {
    padding-top: 50%;
  }

  &__container {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    input {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }

    &__label {
      display: flex;
      height: 100%;
    }
  }
}

.o-form__fileinput {
  &__container {
    border: 1px dashed $primary;

    &.dragging {
      border-style: dotted;
    }

    &.dirty {
      border-style: solid;
    }

    &__label {
      cursor: pointer;
    }
  }
}
</style>
